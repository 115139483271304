import React, { useEffect, useState } from "react";
import "./caseFilterForm.scss";
import { CaseFilterParams } from "../../../models/CaseFilterParams/caseFilterParams.model";
import { Form, Formik } from "formik";
import { Button, Col, Row, Switch } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import DropdownField from "../DropdownField";
import DatePickerField from "../DatePickerField";
import ScanCenterService from "../../../services/ScanCenter/scanCenter.service";
import { ScanCenter } from "../../../models/ScanCenter/scanCenter.model";
import {
  getDropdownOptions,
  IDropdownOptions,
} from "../../utils/dropdownUtils";
import DoctorService from "../../../services/Doctor/doctor.service";
import { Doctor } from "../../../models/Doctor/doctor.model";
import ScanTypeService from "../../../services/ScanType/scanType.service";
import { ScanType } from "../../../models/ScanType/scanType.model";
import BodyPartService from "../../../services/BodyPart/bodyPart.service";
import { BodyPart } from "../../../models/BodyPart/bodyPart.model";
import { CaseStatusEnum } from "../../../enums/caseStatus.enum";
import { ScanCenterBranch } from "../../../models/ScanCenterBranch/scanCenterBranch.model";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";

interface CaseFilterFormProps extends AuthReducerProps {
  formValues: CaseFilterParams;
  onSubmit: (params: CaseFilterParams) => void;
  onReset: () => void;
  isAdmin?: boolean;
  isDoctor?: boolean;
  isScanCenter?: boolean;
}

function CaseFilterForm({
  isAdmin,
  isDoctor,
  isScanCenter,
  formValues,
  user,
  onReset,
  onSubmit,
}: CaseFilterFormProps) {
  const [showFilters, setShowFilters] = useState(false);

  const [scanTypeOptions, setScanTypeOptions] = useState<IDropdownOptions[]>(
    []
  );

  const [bodyPartOptions, setBodyPartOptions] = useState<IDropdownOptions[]>(
    []
  );

  const [scanCenterBranchOptions, setScanCenterBranchOptions] = useState<
    IDropdownOptions[]
  >([]);

  const [selectedScanCenterId, setSelectedScanCenterId] = useState<number>();

  const [doctorOptions, setDoctorOptions] = useState<IDropdownOptions[]>([]);

  const [scanCenterOptions, setScanCenterOptions] = useState<
    IDropdownOptions[]
  >([]);

  const doctorStatusOptions = [
    { label: "Assigned", value: CaseStatusEnum.ASSIGNED },
    { label: "Unassigned", value: CaseStatusEnum.UNASSIGNED },
    { label: "Reported", value: CaseStatusEnum.REPORTED },
  ];

  const otherStatusOptions = [
    { label: "Reported", value: CaseStatusEnum.REPORTED },
    { label: "Report Pending", value: CaseStatusEnum.REPORT_PENDING },
  ];

  const handleFetchScanCenterOptions = (scanCenterId: number) => {
    ScanTypeService.fetchScanCenterBranches(
      scanCenterId,
      (scanCenterBranches: ScanCenterBranch[]) => {
        setScanCenterBranchOptions(
          getDropdownOptions(scanCenterBranches, "name", "id")
        );
      },
      () => {},
      () => {}
    );
  };

  useEffect(() => {
    if (isAdmin) {
      ScanCenterService.fetchScanCenters(
        (scanCenters: ScanCenter[]) => {
          setScanCenterOptions(getDropdownOptions(scanCenters, "name", "id"));
        },
        () => {},
        () => {}
      );
      DoctorService.fetchDoctors(
        (doctors: Doctor[]) => {
          setDoctorOptions(getDropdownOptions(doctors, "doctorName", "id"));
        },
        () => {},
        () => {}
      );
    }
    ScanTypeService.fetchScanTypes(
      (scanTypes: ScanType[]) => {
        setScanTypeOptions(getDropdownOptions(scanTypes, "name", "id"));
      },
      () => {},
      () => {}
    );
    const scanCenterId = isScanCenter
      ? Number(user?.id)
      : Number(selectedScanCenterId);
    if (isScanCenter) {
      handleFetchScanCenterOptions(Number(user?.id));
    }

    BodyPartService.fetchBodyParts(
      (bodyParts: BodyPart[]) => {
        setBodyPartOptions(getDropdownOptions(bodyParts, "name", "id"));
      },
      () => {},
      () => {}
    );
  }, []);

  useEffect(() => {
    if (selectedScanCenterId)
      handleFetchScanCenterOptions(selectedScanCenterId);
  }, [selectedScanCenterId]);

  return (
    <div className="case-filter-form">
      <div className="case-filter-form__show-filter">
        <FilterOutlined onClick={() => setShowFilters(!showFilters)} />
      </div>
      <Formik
        initialValues={formValues}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={null}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              {showFilters && (
                <Row gutter={[20, 10]} align="middle">
                  {isAdmin && (
                    <React.Fragment>
                      <Col span={5}>
                        <DropdownField
                          allowClear
                          placeHolder="Select Scan Center"
                          showSearch
                          title="Scan Center"
                          name="scanCenterId"
                          setFieldValue={setFieldValue}
                          onChange={(value) => {
                            setFieldValue("scanCenterId", value);
                            setFieldValue("scanCenterBranchId", undefined);
                            setScanCenterBranchOptions([]);
                            setSelectedScanCenterId(value);
                          }}
                          options={scanCenterOptions}
                          value={values.scanCenterId}
                        />
                      </Col>
                      <Col span={5}>
                        <DropdownField
                          allowClear
                          placeHolder="Select Doctor"
                          showSearch
                          title="Doctor"
                          name="doctorId"
                          setFieldValue={setFieldValue}
                          options={doctorOptions}
                          value={values.doctorId}
                        />
                      </Col>
                    </React.Fragment>
                  )}
                  <Col span={5}>
                    <DropdownField
                      allowClear
                      placeHolder="Select Scan Type"
                      showSearch
                      title="Scan Type"
                      name="scanTypeId"
                      setFieldValue={setFieldValue}
                      options={scanTypeOptions}
                      value={values.scanTypeId}
                    />
                  </Col>
                  <Col span={5}>
                    <DropdownField
                      allowClear
                      placeHolder="Select Body Part"
                      showSearch
                      title="Body Part"
                      name="bodyPartId"
                      setFieldValue={setFieldValue}
                      options={bodyPartOptions}
                      value={values.bodyPartId}
                    />
                  </Col>
                  <Col span={5}>
                    <DatePickerField
                      title="From Date"
                      name="fromDate"
                      defaultValue={values.fromDate}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                  <Col span={5}>
                    <DatePickerField
                      title="To Date"
                      name="toDate"
                      defaultValue={values.toDate}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                  <Col span={5}>
                    <DropdownField
                      allowClear
                      placeHolder="Select Status"
                      title="Status"
                      name="status"
                      setFieldValue={setFieldValue}
                      options={
                        isDoctor ? doctorStatusOptions : otherStatusOptions
                      }
                      value={values.status}
                    />
                  </Col>
                  <Col span={6}>
                    <DropdownField
                      allowClear
                      placeHolder="Select Scan Center Branch"
                      title="Scan Center Branch"
                      name="scanCenterBranchId"
                      setFieldValue={setFieldValue}
                      options={scanCenterBranchOptions}
                      value={values.scanCenterBranchId}
                    />
                  </Col>
                  <Col span={5}>
                    <Button type="primary" htmlType="submit">
                      Apply
                    </Button>
                    <Button
                      type="default"
                      htmlType="button"
                      className="ml-2"
                      onClick={onReset}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default AuthContainer(CaseFilterForm);
