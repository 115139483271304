import React, { useEffect, useState } from "react";
import "./caseAssignmentForm.scss";
import { CaseDetail } from "../../../../models/CaseDetail/caseDetail.model";
import { Form, Formik } from "formik";
import { Button, Col, Row, Switch } from "antd";
import DropdownField from "../../../../shared/components/DropdownField";
import { IDropdownOptions } from "../../../../shared/utils/dropdownUtils";
import DoctorService from "../../../../services/Doctor/doctor.service";
import { Doctor } from "../../../../models/Doctor/doctor.model";
import AdminCaseService from "../../../../services/Case/AdminCase/adminCase.service";

interface CaseAssignmentFormProps {
  caseDetail: CaseDetail;
  onSuccess: (caseDetail: CaseDetail) => void;
}

function CaseAssignmentForm({
  caseDetail,
  onSuccess,
}: CaseAssignmentFormProps) {
  const [formLoading, setFormLoading] = useState(false);

  const [doctorOptions, setDoctorOptions] = useState<IDropdownOptions[]>([]);

  const handleSubmit = (values: CaseDetail) => {
    const caseDetail = Object.assign(new CaseDetail(), values);
    setFormLoading(true);
    AdminCaseService.assignCase(
      caseDetail.selfAssign,
      caseDetail,
      (caseDetail: CaseDetail) => {
        onSuccess(caseDetail);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  useEffect(() => {
    DoctorService.fetchDoctors(
      (doctors: Doctor[]) => {
        setDoctorOptions(
          doctors.map((doctor) => ({
            label:
              doctor.doctorName +
              " | Assignments today - " +
              doctor.caseCountForToday,
            value: doctor.id,
          }))
        );
      },
      () => {},
      () => {}
    );
  }, []);

  return (
    <div className="case-assignment-form">
      <Formik
        initialValues={caseDetail}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={null}
      >
        {({ values, isValid, setFieldValue }) => {
          return (
            <Form>
              <Row gutter={[0, 20]}>
                <Col span={24} className="case-assignment-form__toggle-wrapper">
                  <span>Assign to doctor</span>
                  <Switch
                    checked={values?.selfAssign}
                    onChange={(checked) => {
                      setFieldValue("selfAssign", checked);
                      setFieldValue("assignedToDoctorId", undefined);
                    }}
                  />
                  <span>Self Assign</span>
                </Col>
                {!values?.selfAssign && (
                  <Col span={24}>
                    <DropdownField
                      placeHolder="Select Doctor"
                      showSearch
                      title="Doctor"
                      name="assignedToDoctorId"
                      setFieldValue={setFieldValue}
                      options={doctorOptions}
                      value={values.assignedToDoctorId}
                    />
                  </Col>
                )}
              </Row>
              <div className="case-assignment-form__submit-wrapper">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={
                    (!values?.selfAssign && !values?.assignedToDoctorId) ||
                    formLoading
                  }
                >
                  Assign
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default CaseAssignmentForm;
